<template>
  <div class="ma-1 pa-1">
    <v-toolbar light>
      <v-toolbar-title>
        Manage Static Pages
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-btn color="primary" @click.stop="resolveCreate">Create Page</v-btn> -->
    </v-toolbar>

    <v-data-table :headers="headers" :items="pages">
        <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item.id)">
                mdi-pencil
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">Reset</v-btn>
        </template>
    </v-data-table>
  </div>
</template>

<script>
import {sync, get, call} from 'vuex-pathify'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      // confirm delete variables
      valid: false,
      deletecode: "",
      todelete: "",
      deluserid: "",
      deletedialog: false,
      foo: false,
      headers: [
        {
          text: 'ID',
          sortable: true,
          value: 'id'
        },
        {
          text: 'Name',
          sortable: true,
          value: 'name',
          align: 'left'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center'
        }
      ] 
    }
  },
  mounted () {
    this.$store.dispatch('staticpages/getPages')
  },
  created () {
      this.initialize()
  },
  computed: {
    ...mapGetters({
        pages: 'staticpages/getPages'
    }),
    current_user: get('newauth@userProfile.email'),
    confirmRule() {
      return [
       () => (this.todelete === this.deletecode) || 'Value is not correct email',
       v => !!v || 'Fill value to confirm'
      ]
    },
  },
  methods: {
    initialize () {
        // console.log('fetching the pages from server')
        this.$store.dispatch('staticpages/getPages')
    },
    resolveCreate (e) {
        // FIXME: improve the random function to prevent conflicts
        console.log('ehhl')
        let randint = Math.floor(Math.random() * 100000000000)
        return this.$router.push({name: 'EditPage', params: {'id': randint}})
    },
    editItem (item) {
        console.log('edit the item: ' + item)
        return this.$router.push({name: 'Edit Page', params: {'id': item }})
    },
    checkUser (item) {
      if (item.email === this.current_user) {
        return true
      } else {
        return false
      }
    },
    getConfirm (item) {
      // console.log(item)
      this.todelete = item.email
      this.deluserid = item.id
      this.deletedialog = true
    },
    delcancel () {
      this.$refs.confirmform.resetValidation()
      this.deletecode = ""
      this.todelete = ""
      this.deluserid = ""
      this.deletedialog = false
    },
    delconfirm () {
      if (this.$refs.confirmform.validate()) {
        this.deletecode = ""
        // delete the user
        this.$store.dispatch('adminusers/deleteUser', {'userid': this.deluserid})
      }
      else {
        this.foo = true
        console.log('the validation is not correct')
      }
      this.deletecode = ""
      this.deluserid = ""
      
      this.deletedialog = false
    }
  }
}
</script>
